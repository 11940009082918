export default {
  async update (Vue, formData) {
    let apiCalls = []
    for (const fieldname in formData) {
      let values = {}
      if (fieldname.startsWith('ubicacion_')) {
        values.idubi_lpresupuestocli = parseInt(fieldname.substring('ubicacion_'.length))
        values.ubicacion = formData[fieldname]
      } else if (fieldname.startsWith('idtipo_ubicacion_')) {
        values.idubi_lpresupuestocli = parseInt(fieldname.substring('idtipo_ubicacion_'.length))
        values.idtipo_ubicacion = formData[fieldname]
      }
      if (values.idubi_lpresupuestocli) {
        apiCalls.push({
          name: 'ubiLpresupuestocliUpdate',
          method: 'ubiLpresupuestocli.update',
          params: { values },
        })
      }
    }
    await Vue.$api.batchCall(apiCalls)
  },
}
