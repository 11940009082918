<template>
  <b10-base>
    <v-list
      dense
    >
      <template
        v-for="linea in lineasPresupuesto"
      >
        <b10-subheader
          :key="linea.idlpresupuestocli"
          :label="linea.title"
          :icon="$vuetify.icons.values.articulo"
        />
        <template
          v-for="formField in linea.formFields"
        >
          <v-list-item
            :key="formField.idubi_lpresupuestocli"
          >
            <v-row>
              <v-col>
                <v-text-field
                  v-model="form[formField.fieldnameUbicacion]"
                  clearable
                  flat
                  label="Ubicación"
                />
              </v-col>
              <v-col>
                <b10-autocomplete
                  v-model="form[formField.fieldnameTipoUbicacion]"
                  :items="tiposUbicaciones"
                  item-value="idtipo_ubicacion"
                  item-text="descripcion"
                  label="Tipo de ubicacion"
                  clearable
                />
              </v-col>
            </v-row>
          </v-list-item>
        </template>
      </template>
    </v-list>
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './UbiLpresupuestocliEditFormData'

export default {
  mixins: [formMixin],
  data () {
    return {
      form: {},
      formRules: {},
      tiposUbicaciones: [],
      lineasPresupuesto: [],
    }
  },
  async created () {
    const resp = await Data.selectLookups(this)
    this.tiposUbicaciones = resp.data.selectTipoUbicacion.result.dataset
    await this.loadForm()
  },
  methods: {
    async loadFormData (id) {
      const formData = await Data.selectFormData(this, id)
      let lastLinea = {
        idlpresupuestocli: -1,
      }
      for (const ubicacion of formData) {
        if (ubicacion.idlpresupuestocli !== lastLinea.idlpresupuestocli) {
          lastLinea = {
            idlpresupuestocli: ubicacion.idlpresupuestocli,
            title: this.$online.lpresupuestocli.title(ubicacion),
            formFields: [],
          }
          this.lineasPresupuesto.push(lastLinea)
        }
        const fieldnameUbicacion = `ubicacion_${ubicacion.idubi_lpresupuestocli}`
        const fieldnameTipoUbicacion = `idtipo_ubicacion_${ubicacion.idubi_lpresupuestocli}`
        // agregar form fields
        this.$set(this.form, fieldnameUbicacion, ubicacion.ubicacion)
        this.$set(this.form, fieldnameTipoUbicacion, ubicacion.idtipo_ubicacion)
        // agregar control
        lastLinea.formFields.push({
          idubi_lpresupuestocli: ubicacion.idubi_lpresupuestocli,
          fieldnameUbicacion,
          fieldnameTipoUbicacion,
        })
      }
    },
  },
}
</script>
