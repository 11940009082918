import { APIFilter } from '@/utils/api'

export default {
  async selectLookups (Vue) {
    let apiCalls = []
    const apiFilterEstado = new APIFilter()
      .addGT('estado', 0)
    apiCalls.push({
      name: 'selectTipoUbicacion',
      method: 'tipoUbicacion.select',
      params: { filter: apiFilterEstado }
    })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectFormData (Vue, id) {
    const apiFilter = new APIFilter()
      .addExact('idpresupuestocli', id)
      .addGT('estado', 0)
    const resp = await Vue.$api.call('ubiLpresupuestocli.select', { filter: apiFilter })
    return resp.data.result.dataset
  },
}
